import React, { useState } from "react";
import {
  Box,
  Grid,
  Modal,
  IconButton,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { smallModalStyle } from "components/common/Styles";
import { primaryButtonHover } from "components/common/Styles";

function RejectionReason({
  open,
  onClose,
  chapterId,
  callChapterWiseRejection,
}) {
  const [reason, setReason] = useState("");

  const handleReject = async () => {
    try {
      // Call the provided rejection handler
      await callChapterWiseRejection(chapterId, "rejected", reason);

      // Close the modal after a successful API call
      handleClose();
    } catch (error) {
      console.error("Error rejecting chapter:", error);
      // Optionally, handle error (e.g., show a notification)
    }
  };

  const handleClose = () => {
    setReason("");
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={smallModalStyle}>
        <Grid item xs={12} container justifyContent="flex-end">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Box>
          <Typography variant="h6" sx={{ color: "rgba(39, 56, 145, 1)" }}>
            Provide reason for rejection
          </Typography>

          <TextField
            label="Rejection reason"
            multiline
            rows={4}
            fullWidth
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Grid container spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  ...primaryButtonHover,
                }}
                onClick={handleReject}
                disabled={!reason.trim()}
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  textTransform: "none",
                  ...primaryButtonHover,
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}

export default RejectionReason;
