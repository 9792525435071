import React, { createContext } from "react";

import { VerifyPageContext } from "./context";
import { useStateUpdate } from "UseStateUpdate";
import { groupChapters } from "service/BookService";
import { mergeAllParagraph } from "service/PageService";
import { pageDetails } from "service/PageService";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import { updateBookStatus } from "service/BookService";
import { useNavigate, useParams } from "react-router-dom";
import { updateParagraph } from "service/PageService";
import { deleteParagraph } from "service/PageService";
import { mergeParagraph } from "service/PageService";
import { createParagraph } from "service/PageService";
import { updatePageStatus } from "service/PageService";
import { getBookDetails } from "service/BookService";
import { deletePage } from "service/PageService";

// Create the provider component

const VerifyPageContextProvider = ({ children }) => {
  let navigate = useNavigate();
  const { bookId } = useParams();

  const openConfirmModal = useStateUpdate(false);
  const openPageViewModal = useStateUpdate(false);
  const openWarningModal = useStateUpdate(false);
  const openGuideLineModal = useStateUpdate(false);
  const warningModalMessage = useStateUpdate("");
  const pages = useStateUpdate(null);
  const isFetching = useStateUpdate(true);
  const pageUrl = useStateUpdate("");
  const pageContents = useStateUpdate([]);
  const isParagraphActionsEnabled = useStateUpdate(false);
  const selectedIndex = useStateUpdate(0);
  const totalPages = useStateUpdate(0);
  const bookDetails = useStateUpdate(null);
  const navigateTo = useStateUpdate(null);
  const mergeAllParaDisabled = useStateUpdate(true);
  const isVerifyCompleted = useStateUpdate(false);
  const onEditOrderIndex = useStateUpdate(null);
  const backDropOpen = useStateUpdate(false);

  const isFilterOnlyUnVerified = useStateUpdate(false);

  const confirmModalData = useStateUpdate({
    icon: null,
    title: null,
    message: null,
    confirmButton: null,
    cancelButton: null,
    onConfirm: null,
    onCancel: null,
  });

  const callGroupChapters = async () => {
    const body = {};
    const result = await groupChapters(bookId, body);
    result.status === 200 && callUpdateBookStatus();
  };

  const callUpdateBookStatus = async () => {
    const body = { statusCode: BookStatusCodes.bookVerified };
    const result = await updateBookStatus(bookId, body);
    result.status === 200 && navigate(`/book-grouping/${bookId}`);
  };

  const setConfirmModalData = (
    icon,
    title,
    message,
    confirmButton,
    cancelButton,
    onConfirm,
    onCancel
  ) => {
    confirmModalData.update({
      icon,
      title,
      message,
      confirmButton,
      cancelButton,
      onConfirm,
      onCancel,
    });
    openConfirmModal.update(true);
  };

  const callMergeAllPara = async () => {
    const result = await mergeAllParagraph(
      pages.state[selectedIndex.state].pageId
    );
    if (result.status === 200) {
      callFetchPageDetails();
      openConfirmModal.update(false);
      backDropOpen.update(false);
    }
  };

  const callFetchPageDetails = () => {
    fetchPageDetails(
      pages.state[selectedIndex.state].pageId,
      pageContents,
      true
    );
  };

  const fetchPageDetails = async (pageId, saveTo, isCurrent) => {
    const result = await pageDetails(pageId);
    if (result.status === 200) {
      const { data } = result.data;
      if (isCurrent) {
        saveTo.update(data);
        mergeAllParaDisabled.update(data.length <= 1);
      } else {
        console.log("1");
        saveTo.update(data[0] ? data[0] : "");
      }
      isFetching.update(false);
    }
  };

  const callUpdateParagraph = async (paraId, body) => {
    const result = await updateParagraph(
      pages.state[selectedIndex.state].pageId,
      paraId,
      body
    );
    if (result.status === 200) {
      callFetchPageDetails();
      openConfirmModal.update(false);
    }
    onEditOrderIndex.update(null);
  };

  const callDeletePara = async (paraId) => {
    const result = await deleteParagraph(
      pages.state[selectedIndex.state].pageId,
      paraId
    );
    result.status === 200 && callFetchPageDetails();
    result.status === 200 && openConfirmModal.update(false);
  };

  const callMergePara = async (
    currentParagraphId,
    mergeWithParagraphId,
    mergeDirection,
    lineBreak
  ) => {
    const body = {
      currentParagraphId,
      mergeWithParagraphId,
      mergeDirection,
    };
    const result = await mergeParagraph(
      pages.state[selectedIndex.state].pageId,
      body,
      lineBreak
    );

    if (result.status === 200) {
      callFetchPageDetails();
      openConfirmModal.update(false);
      backDropOpen.update(false);
    }
  };

  const callCreatePara = async (body) => {
    const result = await createParagraph(
      pages.state[selectedIndex.state].pageId,
      body
    );
    result.status === 200 && callFetchPageDetails();
    result.status === 200 && openConfirmModal.update(false);
    onEditOrderIndex.update(null);
  };

  const callUpdatePageStatus = async (pageId, body) => {
    const result = await updatePageStatus(pageId, body);
    result.status === 200 && fetchBookDetails();
  };

  const fetchBookDetails = async () => {
    const result = await getBookDetails(bookId);
    if (result.status === 200) {
      const { data } = result.data;
      bookDetails.update(data);
    }
  };

  const callDeletePage = async (pageId, index) => {
    let result = await deletePage(pageId);
    if (result.status === 200) {
      openConfirmModal.update(false);
      fetchBookDetails();
      selectedIndex.update(index - 1);
    }
  };

  return (
    <VerifyPageContext.Provider
      value={{
        openConfirmModal,
        openPageViewModal,
        openWarningModal,
        openGuideLineModal,
        warningModalMessage,
        pages,
        isFetching,
        pageUrl,
        pageContents,
        isParagraphActionsEnabled,
        selectedIndex,
        totalPages,
        bookDetails,
        navigateTo,
        mergeAllParaDisabled,
        isVerifyCompleted,
        onEditOrderIndex,
        backDropOpen,
        isFilterOnlyUnVerified,
        confirmModalData,
        bookId,
        pageDetails,
        callGroupChapters,
        setConfirmModalData,
        callMergeAllPara,
        callUpdateParagraph,
        callDeletePara,
        callMergePara,
        callCreatePara,
        fetchPageDetails,
        callUpdatePageStatus,
        callDeletePage,
        fetchBookDetails,
        callFetchPageDetails,
      }}
    >
      {children}
    </VerifyPageContext.Provider>
  );
};

export default VerifyPageContextProvider;
