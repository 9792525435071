import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Stack,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Divider,
  Box,
  TableContainer,
  Table,
  TableBody,
  IconButton,
  Tooltip,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { rowHoverClickStyle } from "components/common/Styles";
import NoDataFound from "components/common/NoDataFound";
import { COLORS } from "constants";
import PlayBtn from "assets/images/play-blue.png";
import PauseBtn from "assets/images/pauseWave.png";
import DownloadBtn from "assets/images/download_cloud.png";
import UploadBtn from "assets/images/upload_cloud.png";
import copyAudioBtn from "assets/images/copy-audio.png";
import { copyChapterAudio } from "service/BookService";
import { UNEXPECTED_ERROR_MESSAGE } from "constants/otherConstant";
import { useStateUpdate } from "UseStateUpdate";
import Toast from "./Toast";
import { bookRecordedPage } from "./Styles";
import { BooksRecordedContext } from "context/context";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import RejectionReason from "../modals/RejectionReason";
import { capitalize } from "utils/WordsUtils";

const headCells = [
  { id: "number", label: "#" },
  { id: "title", label: "Title" },
  { id: "pages", label: "Pages" },
];

function ChapterTable(props) {
  const {
    bookDetails,
    chapters,
    selectedChapter,
    isWavePlaying,
    currentUrlToPlay,
    pauseAudio,
    playAudio,
    handleDownload,
    currentChapterToUpload,
    openAudioBookModal,
    isWaveLoading,
    status,
    callChapterWiseRejection,
    retailSampleAudioUploadModal,
  } = useContext(BooksRecordedContext);

  const isFinalStatus =
    status === BookStatusCodes.readyForProduction ||
    status === BookStatusCodes.completed;
  const { inOptimization, isCompleted, fetchBookDetails } = props;
  const classes = rowHoverClickStyle();
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  // Initialize state for radio selections
  const [radioSelections, setRadioSelections] = useState({});

  // New state for rejection modal
  const [rejectionModalOpen, setRejectionModalOpen] = useState(false);
  const [currentChapterId, setCurrentChapterId] = useState(null);

  // Handle radio button change (Accept/Reject)
  //const handleRadioChange = (chapterId, value) => {
  // const acceptedOrRejected = value === "accepted" ? "accepted" : "rejected";

  //callChapterWiseRejection(chapterId, acceptedOrRejected, "");
  // };

  // Handle radio button change (Accept/Reject)
  const handleRadioChange = (chapterId, value) => {
    const chapter = chapters.state.find((ch) => ch.chapterId === chapterId);

    if (value === "accepted") {
      if (!chapter.optimizedAudioUrl) {
        calltoast(
          "warning",
          `Please upload optimized audio for this ${chapter.chapterName}`
        );

        return;
      }

      callChapterWiseRejection(chapterId, "accepted", "");
    } else {
      setCurrentChapterId(chapterId);
      setRejectionModalOpen(true);
    }
  };

  // Function to render play button
  const handlePlayAudio = (audioUrl) => {
    if (isWavePlaying.state) {
      pauseAudio();
      if (currentUrlToPlay.state !== audioUrl) {
        currentUrlToPlay.update(audioUrl);
      }
    } else {
      if (currentUrlToPlay.state === audioUrl) {
        playAudio();
      } else {
        currentUrlToPlay.update(audioUrl);
      }
    }
  };

  const handleCopyAudio = async (bookId, chapterId, audioUrl) => {
    let body = {
      audioUrl: audioUrl,
    };
    let response = await copyChapterAudio(bookId, chapterId, body);
    if (response.status === 200) {
      fetchBookDetails();
      calltoast("success", "Optimized audio added");
    } else {
      calltoast("error", UNEXPECTED_ERROR_MESSAGE);
    }
  };

  const calltoast = async (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };
  const closeRejectionModal = () => {
    setRejectionModalOpen(false);
    setCurrentChapterId(null);
  };

  const renderPlayButton = (audioUrl, disablePlay = false) => (
    <Tooltip title="Play">
      <IconButton
        disabled={isWaveLoading.state || disablePlay}
        color="primary"
        onClick={() => handlePlayAudio(audioUrl)}
      >
        <img
          src={
            isWavePlaying.state && currentUrlToPlay.state === audioUrl
              ? PauseBtn
              : PlayBtn
          }
          style={bookRecordedPage.button}
          alt="play"
        />
      </IconButton>
    </Tooltip>
  );

  const renderCopyButton = (bookId, chapterId, audioUrl) => (
    <Tooltip title="Copy audio from left">
      <IconButton
        disabled={isWaveLoading.state}
        color="primary"
        onClick={() => handleCopyAudio(bookId, chapterId, audioUrl)}
      >
        <img
          src={copyAudioBtn}
          style={bookRecordedPage.button}
          alt="Copy audio from left"
        />
      </IconButton>
    </Tooltip>
  );

  const renderUploadButton = (chapter) => (
    <Tooltip title="Upload optimized audio">
      <IconButton
        color="primary"
        onClick={() => {
          currentChapterToUpload.update(chapter);
          openAudioBookModal.update(true);
        }}
      >
        <img src={UploadBtn} style={bookRecordedPage.button} alt="upload" />
      </IconButton>
    </Tooltip>
  );

  const renderDownloadButton = (audioUrl) => (
    <Tooltip title="Download audio">
      <IconButton color="primary" onClick={() => handleDownload(audioUrl)}>
        <img src={DownloadBtn} style={bookRecordedPage.button} alt="download" />
      </IconButton>
    </Tooltip>
  );

  const handleRetailSampleUploadOptimizedAudio = () => {
    // currentChapterToUpload.update();
    retailSampleAudioUploadModal.update(true);
  };

  const renderRetailSampleUploadButton = () => {
    return (
      <Tooltip title="Upload Retail Sample Optimized Audio">
        <IconButton
          color="primary"
          onClick={() => {
            handleRetailSampleUploadOptimizedAudio();
          }}
        >
          <img src={UploadBtn} style={bookRecordedPage.button} alt="upload" />
        </IconButton>
      </Tooltip>
    );
  };
  const renderRadioButtons = (chapter) => {
    return (
      <RadioGroup
        row
        value={chapter.isRejected}
        onChange={(e) => handleRadioChange(chapter.chapterId, e.target.value)}
      >
        <FormControlLabel value="accepted" control={<Radio />} label="Accept" />
        <FormControlLabel value="rejected" control={<Radio />} label="Reject" />
      </RadioGroup>
    );
  };

  const hardcodedRow = (
    <TableRow
      key="hardcoded-retail-sample"
      hover
      classes={{ hover: classes.hover }}
    >
      <TableCell align="center">
        <Typography variant="font15">0</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="font15">Retail sample</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="font15">0 - 0</Typography>
      </TableCell>
      {inOptimization && !isFinalStatus && (
        <TableCell align="center">
          <Typography variant="font15">- -</Typography>
        </TableCell>
      )}
      <TableCell align="center">
        <Stack direction="row" justifyContent="center" alignItems="center">
          {bookDetails.state.retailSample &&
            renderPlayButton(bookDetails.state.retailSample)}
          {inOptimization && renderRetailSampleUploadButton()}
          {isCompleted &&
            bookDetails.state.retailSample &&
            renderDownloadButton(bookDetails.state.retailSample)}
        </Stack>
      </TableCell>
      {!isFinalStatus && (
        <TableCell align="center">
          <div>- -</div>
        </TableCell>
      )}
    </TableRow>
  );

  const chapterRows = [
    hardcodedRow,
    ...chapters.state.map((chapter, index) => (
      <TableRow
        key={chapter.chapterId}
        hover
        classes={{ hover: classes.hover }}
        onClick={() => selectedChapter.update(chapter)}
      >
        <TableCell align="center">
          <Typography variant="font15">{index + 1}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="font15">
            {capitalize(chapter.chapterName)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="font15">{`${chapter.startPageNumber ?? "-"} - ${
            chapter.endPageNumber ?? "-"
          }`}</Typography>
        </TableCell>
        {inOptimization && !isFinalStatus && (
          <TableCell align="center">
            <Stack direction="row" justifyContent="center" alignItems="center">
              {chapter.audioUrl && renderPlayButton(chapter.audioUrl)}
              {chapter.audioUrl && renderDownloadButton(chapter.audioUrl)}
              {!chapter.audioUrl && <div>- -</div>}
            </Stack>
          </TableCell>
        )}
        <TableCell align="center">
          <Stack direction="row" justifyContent="center" alignItems="center">
            {!chapter.audioUrl && <div>- -</div>}
            {chapter.optimizedAudioUrl && chapter.audioUrl
              ? renderPlayButton(chapter.optimizedAudioUrl, false)
              : chapter.audioUrl &&
                inOptimization &&
                renderCopyButton(
                  chapter.bookId,
                  chapter.chapterId,
                  chapter.audioUrl
                )}
            {chapter.audioUrl && inOptimization && renderUploadButton(chapter)}
            {isCompleted &&
              chapter.optimizedAudioUrl &&
              chapter.audioUrl &&
              renderDownloadButton(chapter.optimizedAudioUrl)}
          </Stack>
        </TableCell>
        {!isFinalStatus && (
          <TableCell align="center">
            <Stack direction="row" justifyContent="center" alignItems="center">
              {(chapter.optimizedAudioUrl || chapter.audioUrl) &&
                renderRadioButtons(chapter)}
            </Stack>
          </TableCell>
        )}
      </TableRow>
    )),
  ];

  let tableContent;

  if (chapters.state.length === 0) {
    tableContent = <NoDataFound colSpanValue={inOptimization ? "6" : "5"} />;
  } else {
    tableContent = chapterRows;
  }

  return (
    <Grid container item xs={12}>
      <Paper variant="outlined" sx={{ width: "100%" }}>
        <Box sx={{ width: { xs: "90vw", sm: "100%" } }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align="center">
                      <Typography
                        variant="font15b"
                        color={COLORS.primary}
                        noWrap
                      >
                        {headCell.label}
                      </Typography>
                    </TableCell>
                  ))}
                  {inOptimization && !isFinalStatus && (
                    <TableCell align="center">
                      <Typography
                        variant="font15b"
                        color={COLORS.primary}
                        noWrap
                      >
                        Audio
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell align="center">
                    <Typography variant="font15b" color={COLORS.primary} noWrap>
                      Optimized audio
                    </Typography>
                  </TableCell>
                  {!isFinalStatus && (
                    <TableCell align="center">
                      <Typography
                        variant="font15b"
                        color={COLORS.primary}
                        noWrap
                      >
                        Action
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>{tableContent}</TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Divider />
      </Paper>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
      <RejectionReason
        open={rejectionModalOpen}
        onClose={closeRejectionModal}
        chapterId={currentChapterId}
        callChapterWiseRejection={callChapterWiseRejection}
      />
    </Grid>
  );
}

export default ChapterTable;
