import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { COLORS } from "constants";
import AudioPlayButton from "./AudioPlayButton";
import { BooksRecordedContext } from "context/context";
import { capitalize } from "utils/WordsUtils";

function PageDetails() {
  const {
    selectedChapter,
    isWaveLoading,
    isWavePlaying,
    currentWaveTime,
    handlePlayButton,
  } = useContext(BooksRecordedContext);
  return (
    <Grid container item xs={10.5}>
      <Grid container justifyContent="flex-start" alignItems="center">
        <Grid
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          direction="column"
          item
          xs={12}
        >
          <Typography variant="font16b">
            {capitalize(selectedChapter.state.chapterName)}
          </Typography>
          <Typography variant="font15" color={COLORS.dimGrey}>
            {`Pages ${selectedChapter.state.startPageNumber ?? "-"} - ${
              selectedChapter.state.endPageNumber ?? "_"
            }`}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="flex-end"
          item
          xs={12}
        >
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-end"
            item
            xs={1.5}
          >
            <AudioPlayButton
              {...{
                isWaveLoading,
                isWavePlaying,
                currentWaveTime,
                handlePlayButton,
              }}
            />
          </Grid>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-end"
            item
            xs={10.5}
          >
            <div style={{ width: "95%" }} id="waveform"></div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PageDetails;
