import React, { Fragment } from "react";
import {
  Grid,
  Modal,
  Box,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Stack,
  IconButton,
  Divider,
  Input,
} from "@mui/material/";
import CloseIcon from "@mui/icons-material/Close";
import { smallModalStyle } from "../common/Styles";
import { useStateUpdate } from "UseStateUpdate";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { COLORS } from "constants";
import { uploadPdf } from "service/BookService";

const Footer = ({ isUploading }) => {
  return (
    <Box p={4}>
      <Grid container justifyContent="center" item xs={12}>
        <Typography variant="font15" style={{ textAlign: "center" }}>
          {isUploading.state
            ? " Note: PDF is uploading, extracting the content may take a few minutes, depending on the number of pages in your document."
            : " Note: After uploading the PDF, extracting the content may take a few minutes, depending on the number of pages in your document."}
        </Typography>
      </Grid>
    </Box>
  );
};

const UploadBook = ({ handleFileChange }) => {
  return (
    <Fragment>
      <Grid container justifyContent="center" item xs={12}>
        <Typography variant="font17">Select book here</Typography>
      </Grid>
      <Grid container justifyContent="center" item xs={12}>
        <Input
          type="file"
          fullWidth
          inputProps={{ accept: "application/pdf" }}
          onChange={handleFileChange}
          style={{
            backgroundColor: "#273891",
            color: COLORS.white,
            paddingLeft: "10px",
          }}
        />
        <Typography variant="font14">Please upload only .pdf files</Typography>
      </Grid>
    </Fragment>
  );
};

const UploadingBook = () => {
  return (
    <Fragment>
      <Grid container justifyContent="center" item xs={12}>
        <Typography variant="font17">Preparing pdf upload</Typography>
      </Grid>
      <Grid container justifyContent="center" item xs={12}>
        <Typography variant="font17">
          <CircularProgress />
        </Typography>
      </Grid>
      <Grid container justifyContent="center" item xs={12}>
        <Typography variant="font17">Please wait</Typography>
      </Grid>
    </Fragment>
  );
};

const UploadCompleted = ({ handleClose }) => {
  return (
    <Fragment>
      <Paper variant={"outlined"}>
        <Box py={4}>
          <Stack spacing={4} alignItems="center">
            <DraftsOutlinedIcon color="primary" style={{ fontSize: "100px" }} />
            <Typography
              variant="font17"
              width={"75%"}
              style={{ textAlign: "center" }}
            >
              The system is currently extracting the content from your document.
              We will be sending a notification mail once it is completed.
            </Typography>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={handleClose}
            >
              <Typography variant="font12b" mx={6}>
                Close
              </Typography>
            </Button>
          </Stack>
        </Box>
      </Paper>
    </Fragment>
  );
};

const UploadingFailed = ({ handleClose, handleTryAgain }) => {
  return (
    <Fragment>
      <Paper variant={"outlined"}>
        <Box py={4}>
          <Stack spacing={4} alignItems="center">
            <ErrorOutlineOutlinedIcon
              color="error"
              style={{ fontSize: "100px" }}
            />
            <Stack spacing={1} alignItems="center">
              <Typography variant="font17" style={{ textAlign: "center" }}>
                An error occurred.
              </Typography>
              <Typography variant="font17" style={{ textAlign: "center" }}>
                Unable to process your request at the moment.
              </Typography>
            </Stack>
            <Stack direction="row" spacing={3} alignItems="center">
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={handleTryAgain}
              >
                <Typography variant="font12b" mx={4}>
                  Try again
                </Typography>
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={handleClose}
              >
                <Typography variant="font12b" mx={4}>
                  Close
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Paper>
    </Fragment>
  );
};

function BookUploadModal({ open, bookId, fetchDetails }) {
  const isUploading = useStateUpdate(false);
  const isUploaded = useStateUpdate(false);
  const isUploadingFailed = useStateUpdate(false);

  const handleClose = () => {
    isUploading.update(false);
    isUploaded.update(false);
    isUploadingFailed.update(false);
    open.update(false);
  };

  const handleTryAgain = () => {
    isUploading.update(false);
    isUploaded.update(false);
    isUploadingFailed.update(false);
  };

  const handleFileChange = async (event) => {
    isUploading.update(true);
    let files = event.target.files || event.dataTransfer.files;
    let formData = new FormData();
    formData.append("bookPdf", files[0]);
    let result = await uploadPdf(bookId, formData);
    if (result.status === 200) {
      isUploaded.update(true);
      fetchDetails();
    } else {
      isUploadingFailed.update(true);
    }
  };

  return (
    <Fragment>
      <Modal open={open.state} onClose={handleClose}>
        <Box sx={smallModalStyle}>
          {!isUploaded.state && (
            <Grid item xs={12} container justifyContent="flex-end">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
          <Box ml={{ xs: 2, md: 3 }} mr={{ xs: 2, md: 5 }}>
            {isUploaded.state ? (
              <UploadCompleted {...{ handleClose }} />
            ) : isUploadingFailed.state ? (
              <UploadingFailed {...{ handleTryAgain, handleClose }} />
            ) : (
              <Fragment>
                <Grid mb={2}>
                  <Typography variant="font20b" color={COLORS.primary}>
                    Upload book
                  </Typography>
                </Grid>
                <Paper variant={"outlined"}>
                  <Box p={4}>
                    <Grid spacing={4} container>
                      {isUploading.state ? (
                        <UploadingBook />
                      ) : (
                        <UploadBook handleFileChange={handleFileChange} />
                      )}
                    </Grid>
                  </Box>
                  <Divider />
                  <Footer isUploading={isUploading} />
                </Paper>
              </Fragment>
            )}
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default BookUploadModal;
