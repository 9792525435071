import { apiCall } from "service/AuthProvider";
import { apiCallWithFormData } from "./AuthProvider";

const SERVICE_URL = "page-service/";

export const updatePageStatus = async (pageId, body) => {
  const url = `${SERVICE_URL}pages/${pageId}/update`;
  return apiCall("put", url, body);
};

export const deletePage = async (pageId) => {
  const url = `${SERVICE_URL}pages/${pageId}`;
  return apiCall("delete", url);
};

export const pageDetails = async (pageId) => {
  const url = `${SERVICE_URL}pages/${pageId}`;
  return apiCall("get", url);
};

export const createParagraph = async (pageId, body) => {
  const url = `${SERVICE_URL}pages/${pageId}/paragraphs`;
  return apiCall("post", url, body);
};

export const updateParagraph = async (pageId, paraId, body) => {
  const url = `${SERVICE_URL}pages/${pageId}/paragraphs/${paraId}`;
  return apiCall("put", url, body);
};

export const deleteParagraph = async (pageId, paraId) => {
  const url = `${SERVICE_URL}pages/${pageId}/paragraphs/${paraId}`;
  return apiCall("delete", url);
};

export const mergeParagraph = async (pageId, body, lineBreak) => {
  const url = `${SERVICE_URL}pages/${pageId}/paragraphs/merge?lineBreak=${lineBreak}`;
  return apiCall("post", url, body);
};

export const mergeAllParagraph = async (pageId) => {
  const url = `${SERVICE_URL}pages/${pageId}/paragraphs/merge-all`;
  return apiCall("post", url);
};

export const uploadAudio = async (pageId, paragraphId, body, isOptimized) => {
  const url = `${SERVICE_URL}pages/${pageId}/paragraphs/${paragraphId}/audio/upload?isOptimized=${isOptimized}`;
  return apiCallWithFormData("put", url, body);
};

export const verifyAllPages = async (bookId, body) => {
  const url = `${SERVICE_URL}pages/books/${bookId}/update`;
  return apiCallWithFormData("put", url, body);
};

export const deleteBlankPages = async (pageIds) => {
  const url = `${SERVICE_URL}pages/emptypage/delete`;
  return apiCall("delete", url, pageIds);
};
