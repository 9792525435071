import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStateUpdate } from "UseStateUpdate";
import { getAudioUrl } from "utils/GetUrl";
import { updateBookStatus } from "service/BookService";
import { COLORS, BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import { updateProject } from "service/ProjectService";
import { useIsAuthor } from "utils/GetUserDetail";
import { useIsAudioEngineer } from "utils/GetUserDetail";
import { getAudioPlayTime } from "utils/DateTimeUtils";
import CursorPlugin from "wavesurfer.js/dist/plugin/wavesurfer.cursor";
import WaveSurfer from "wavesurfer.js";
import { getChaptersWithAudio } from "service/BookService";
import { BooksRecordedContext } from "./context";
import { chapterWiseRejection } from "service/BookService";

let waveSurfer;

// Create the provider component
const BooksRecordedContextProvider = ({ children, status }) => {
  const { bookId } = useParams();
  let navigate = useNavigate();
  const isAuthor = useIsAuthor();
  const isAudioEngineer = useIsAudioEngineer();
  const bookDetails = useStateUpdate(null);
  const chapters = useStateUpdate(null);
  const selectedChapter = useStateUpdate(null);
  const isWavePlaying = useStateUpdate(false);
  const currentUrlToPlay = useStateUpdate("");
  const currentChapterToUpload = useStateUpdate({});
  const openAudioBookModal = useStateUpdate(false);
  const isWaveLoading = useStateUpdate(false);
  const retailSampleAudioUploadModal = useStateUpdate(false);
  const openConfirmModal = useStateUpdate(false);
  const openRejectMessageModal = useStateUpdate(false);
  const isConfirmBtnDisable = useStateUpdate(true);
  const currentWaveTime = useStateUpdate("00:00:00");
  const confirmModalData = useStateUpdate({
    icon: null,
    title: null,
    message: null,
    confirmButton: null,
    cancelButton: null,
    onConfirm: null,
    onCancel: null,
  });

  const setConfirmModalData = (
    icon,
    title,
    message,
    confirmButton,
    cancelButton,
    onConfirm,
    onCancel
  ) => {
    confirmModalData.update({
      icon,
      title,
      message,
      confirmButton,
      cancelButton,
      onConfirm,
      onCancel,
    });
    openConfirmModal.update(true);
  };

  const pauseAudio = () => {
    waveSurfer?.pause();
    isWavePlaying.update(false);
  };

  const playAudio = (e) => {
    waveSurfer.play();
    isWavePlaying.update(true);
  };

  const handleDownload = (audioUrl) => {
    const url = getAudioUrl(audioUrl) + "?t=" + new Date().getTime();
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const callUpdateBookStatus = async (statusCode) => {
    const body = { statusCode: statusCode };
    const result = await updateBookStatus(bookId, body);
    if (result.status === 200) {
      if (statusCode === BookStatusCodes.completed) {
        let newProjectDetails = {
          isComplete: true,
        };

        await updateProject(bookDetails.state.projectId, newProjectDetails);
      }
      isAudioEngineer || isAuthor
        ? navigate(`/dashboard`)
        : navigate(`/projects/${bookDetails.state.projectId}`);
    }
  };

  const handlePlayButton = () => {
    if (isWavePlaying.state) {
      pauseAudio();
    } else {
      if (
        currentUrlToPlay.state === selectedChapter.state.audioUrl ||
        currentUrlToPlay.state === selectedChapter.state.optimizedAudioUrl ||
        currentUrlToPlay.state === bookDetails.state.retailSample
      ) {
        playAudio();
      } else {
        currentUrlToPlay.update(
          status === BookStatusCodes.bookRecorded
            ? selectedChapter.state.audioUrl
            : selectedChapter.state.optimizedAudioUrl
        );
      }
    }
  };

  const fetchBookChaptersWithAudio = async () => {
    const result = await getChaptersWithAudio(bookId);
    if (result.status === 200) {
      const { data } = result.data;
      bookDetails.update(data);
    }
  };

  const callChapterWiseRejection = async (
    chapterId,
    isRejected,
    rejectionReason
  ) => {
    const body = { isRejected: isRejected, rejectionReason: rejectionReason };

    const result = await chapterWiseRejection(bookId, chapterId, body);
    if (result.status === 200) {
      fetchBookChaptersWithAudio();
    }
  };

  const checkOptimizedAudio = () => {
    let btnDisabled = false;
    if (status !== BookStatusCodes.completed) {
      chapters.state.forEach((chapter) => {
        if (!(chapter.optimizedAudioUrl || chapter.audioUrl)) {
          return;
        }
        if (chapter.isRejected === "rejected") {
          return;
        }

        if (!chapter.optimizedAudioUrl)
          if (chapter.audioUrl) btnDisabled = true;
        if (chapter.isRejected === "idle") {
          btnDisabled = true;
        }
      });
      if (
        status === BookStatusCodes.readyForProduction &&
        (!bookDetails.state?.retailSample ||
          bookDetails.state?.retailSample === "processing")
      ) {
        btnDisabled = true;
      }
    }
    isConfirmBtnDisable.update(btnDisabled);
  };

  const loadWaveSurfer = (urlIs) => {
    isWaveLoading.update(true);
    waveSurfer = WaveSurfer.create({
      container: document.querySelector("#waveform"),
      barWidth: 3,
      barRadius: 3,
      barGap: 2,
      barMinHeight: 1,
      cursorWidth: 1,
      backend: "WebAudio",
      progressColor: COLORS.primary,
      responsive: true,
      waveColor: "#C4C4C4",
      cursorColor: "transparent",
      plugins: [
        CursorPlugin.create({
          showTime: true,
          opacity: 1,
          customShowTimeStyle: {
            "background-color": "#000",
            color: "#fff",
            padding: "2px",
            "font-size": "10px",
          },
          customWaveColor: function (percents) {
            return percents <= 50 ? "#ff0000" : "#00ff00";
          },
        }),
      ],
    });
    waveSurfer.load(urlIs);
    waveSurfer.on("ready", () => {
      playAudio();
      isWaveLoading.update(false);
    });
    waveSurfer.on("finish", () => {
      isWavePlaying.update(false);
    });
    waveSurfer.on("audioprocess", handleAudioProcess);
  };

  const handleAudioProcess = () => {
    const currentTime = waveSurfer.getCurrentTime();
    currentWaveTime.update(getAudioPlayTime(currentTime));
  };

  return (
    <BooksRecordedContext.Provider
      value={{
        bookId,
        bookDetails,
        chapters,
        selectedChapter,
        isWavePlaying,
        currentUrlToPlay,
        currentChapterToUpload,
        openAudioBookModal,
        isWaveLoading,
        retailSampleAudioUploadModal,
        openRejectMessageModal,
        isConfirmBtnDisable,
        currentWaveTime,
        status,
        openConfirmModal,
        confirmModalData,
        waveSurfer,
        pauseAudio,
        playAudio,
        handleDownload,
        setConfirmModalData,
        callUpdateBookStatus,
        handlePlayButton,
        fetchBookChaptersWithAudio,
        checkOptimizedAudio,
        loadWaveSurfer,
        callChapterWiseRejection,
      }}
    >
      {children}
    </BooksRecordedContext.Provider>
  );
};

export default BooksRecordedContextProvider;
