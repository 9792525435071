import React, { Fragment, useEffect } from "react";

import {
  Grid,
  Paper,
  Typography,
  Box,
  Stack,
  Skeleton,
  Link,
} from "@mui/material";

import { useStateUpdate } from "UseStateUpdate";
import StatusProgress from "./StatusProgress";
import { COLORS, BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import { useNavigate } from "react-router-dom";

const createStatusFigures = (title, count, total, pathColor, status) => ({
  title,
  count,
  total,
  pathColor,
  status,
});

const skeleton = () => {
  return (
    <Fragment>
      {[1, 2, 3, 4].map((index) => (
        <Grid key={index} item xs={12} sm={6}>
          <Paper variant="outlined">
            <Box p={1} pb={{ xs: 1, md: 4 }}>
              <Stack spacing={2}>
                <Skeleton animation="wave" variant="rectangular" />
                <Grid container pt={{ xs: 1, md: 2 }}>
                  <Grid item xs={6}>
                    <Skeleton animation="wave" variant="rectangular" />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Skeleton animation="wave" variant="rectangular" />
                  </Grid>
                </Grid>
              </Stack>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Fragment>
  );
};

function AdminDashboardSummary(props) {
  const { dashBoardData } = props;
  let navigate = useNavigate();
  const statusData = useStateUpdate([]);

  useEffect(() => {
    if (dashBoardData.state) {
      const bookStatus = dashBoardData.state.bookStatusData.bookStatus;

      const data = [
        [
          "Books created",
          bookStatus.BOOKCREATED ?? 0,
          COLORS.uploadBooksCreated,
          BookStatusCodes.bookCreated,
        ],
        [
          "Books processing",
          bookStatus.BOOKPROCESSING ?? 0,
          COLORS.booksProcessingStatus,
          BookStatusCodes.bookProcessing,
        ],
        [
          "Books uploaded",
          bookStatus.BOOKUPLOADED ?? 0,
          COLORS.uploadedBooksStatus,
          BookStatusCodes.bookUploaded,
        ],
        [
          "Books verified",
          bookStatus.BOOKVERIFIED ?? 0,
          COLORS.verifyBooksStatus,
          BookStatusCodes.bookVerified,
        ],
        [
          "Grouped chapters",
          bookStatus.CHAPTERGROUPED ?? 0,
          COLORS.groupChaptersStatus,
          BookStatusCodes.chapterGrouped,
        ],
        [
          "Books recording",
          bookStatus.BOOKRECORDING ?? 0,
          COLORS.recordingBooksStatus,
          BookStatusCodes.bookRecording,
        ],
        [
          "Audio merging",
          bookStatus.AUDIOMERGING ?? 0,
          COLORS.audioMergingStatus,
          BookStatusCodes.audioMerging,
        ],
        [
          "Books recorded",
          bookStatus.BOOKRECORDED ?? 0,
          COLORS.recordedBooksStatus,
          BookStatusCodes.bookRecorded,
        ],
        [
          "Optimization in progress",
          bookStatus.OPTIMIZATIONINPROGRESS ?? 0,
          COLORS.audioOptimizationInProgrssStatus,
          BookStatusCodes.optimizationInProgress,
        ],
        [
          "Audio optimized",
          bookStatus.AUDIOOPTIMIZED ?? 0,
          COLORS.audioOptimizationStatus,
          BookStatusCodes.audioOptimized,
        ],
        [
          "Ready for production",
          bookStatus.READYFORPRODUCTION ?? 0,
          COLORS.readyForProductionStatus,
          BookStatusCodes.readyForProduction,
        ],
        [
          "Completed books",
          bookStatus.COMPLETED ?? 0,
          COLORS.completedBooksStatus,
          BookStatusCodes.completed,
        ],
        [
          "Miscellaneous",
          getMiscellaneousCount(),
          COLORS.miscellaneous,
          "MISCELLANEOUS",
        ],
      ];

      const statusDataIs = data.map(([title, count, pathColor, status]) =>
        createStatusFigures(
          title,
          count,
          dashBoardData.state.bookStatusData.totalBooks,
          pathColor,
          status
        )
      );

      statusData.update(statusDataIs);
    }
  }, [dashBoardData.state]);

  const getMiscellaneousCount = () => {
    const bookStatus = dashBoardData.state.bookStatusData.bookStatus;
    const miscellaneousKeys = [
      "BOOKUPLOADING",
      "BOOKEXTFAILED",
      "AUDIOMERGEFAILED",
    ];

    let miscellaneousCount = miscellaneousKeys.reduce((count, key) => {
      return count + (bookStatus[key] || 0);
    }, 0);

    return miscellaneousCount;
  };

  return dashBoardData.state ? (
    <Fragment>
      <Grid container item xs={6} md={4}>
        <Paper variant="outlined" sx={{ width: "100%" }}>
          <Box pt={1} pb={{ xs: 1, md: 4 }} px={2}>
            <Stack spacing={2}>
              <Typography variant="font15b" color={COLORS.primary} noWrap>
                Authors
              </Typography>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                pt={{ xs: 1, md: 2 }}
              >
                <Link
                  href=""
                  variant="font35b"
                  underline="none"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/authors");
                  }}
                >
                  {dashBoardData.state.noOfAuthors}
                </Link>
              </Grid>
            </Stack>
          </Box>
        </Paper>
      </Grid>
      <Grid container item xs={6} md={4}>
        <Paper variant="outlined" sx={{ width: "100%" }}>
          <Box pt={1} pb={{ xs: 1, md: 4 }} px={2}>
            <Stack spacing={2}>
              <Typography variant="font15b" color={COLORS.primary} noWrap>
                Projects
              </Typography>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                pt={{ xs: 1, md: 2 }}
              >
                <Link
                  href=""
                  variant="font35b"
                  underline="none"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/projects");
                  }}
                >
                  {dashBoardData.state.noOfProjects}
                </Link>
              </Grid>
            </Stack>
          </Box>
        </Paper>
      </Grid>
      <Grid container item xs={6} md={4}>
        <Paper variant="outlined" sx={{ width: "100%" }}>
          <Box pt={1} pb={{ xs: 1, md: 4 }} px={2}>
            <Stack spacing={2}>
              <Typography variant="font15b" color={COLORS.primary} noWrap>
                Audio engineers
              </Typography>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                pt={{ xs: 1, md: 2 }}
              >
                <Link
                  href=""
                  variant="font35b"
                  underline="none"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/audio-engineers");
                  }}
                >
                  {dashBoardData.state.noOfAudioEngineers}
                </Link>
              </Grid>
            </Stack>
          </Box>
        </Paper>
      </Grid>
      {statusData.state.map((figure) => (
        <Grid key={figure.title} container item xs={6} sm={4}>
          <Paper variant="outlined" sx={{ width: "100%" }}>
            <Box pt={1} pb={{ xs: 1, md: 4 }} px={2}>
              <Stack spacing={2}>
                <Typography variant="font15b" color={COLORS.primary} noWrap>
                  {figure.title}
                </Typography>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  pt={{ xs: 1, md: 2 }}
                >
                  <StatusProgress
                    percentage={figure.count / figure.total}
                    count={figure.count}
                    pathColor={figure.pathColor}
                    status={figure.status}
                  />
                </Grid>
              </Stack>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Fragment>
  ) : (
    skeleton()
  );
}

export default AdminDashboardSummary;
