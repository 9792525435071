import React, { useRef } from "react";
import { RecordingPageContext } from "./context";
import { useStateUpdate } from "UseStateUpdate";
import { pageDetails } from "service/PageService";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import { updateBookStatus } from "service/BookService";
import { useNavigate, useParams } from "react-router-dom";
import { updateParagraph } from "service/PageService";
import { deleteParagraph } from "service/PageService";
import { createParagraph } from "service/PageService";
import { BIT_RATE as bitRate } from "constants/otherConstant";
import { TEXT_TO_RECORD_NORMAL_FONT_SIZE } from "constants/otherConstant";
import { PAGE_VIEW_NORMAL_SIZE } from "constants/otherConstant";
import { useIsAuthor } from "utils/GetUserDetail";

import { getAudioBufferFromBlob } from "components/Voices/sections/utils";
import { updatePageStatus } from "service/PageService";
import { getBookDetailsForRecording } from "service/BookService";
import { getAudioUrl } from "utils/GetUrl";
import { uploadAudio } from "service/PageService";
import { getAudioLength } from "utils/AudioLength";
import { getUserDetail } from "service/UserService";
import UAParser from "ua-parser-js";
import { versionFormatToTwoSegments } from "components/Voices/sections/utils";
import { getAudioType } from "utils/getAudioType";

const adminRoleId = process.env.REACT_APP_ENV === "dev" ? 1 : 2;

const MicRecorder = require("mic-recorder-to-mp3");

const recorder = new MicRecorder({
  bitRate: bitRate,
});

let sound;

let mediaRecorder = null;
let audioBlobs = [];
let capturedStream = null;

// Create the provider component
const RecordingPageContextProvider = ({ children }) => {
  const { bookId } = useParams();
  const isAuthor = useIsAuthor();
  const navigate = useNavigate();
  const selectedIndex = useStateUpdate(0);
  const totalPages = useStateUpdate(0);
  const openGuideLineModal = useStateUpdate(false);
  const isRecordingCompleted = useStateUpdate(false);
  const openUnRecordedPagesModal = useStateUpdate(false);
  const pagesNotRecorded = useStateUpdate([]);
  const pageContents = useStateUpdate([]);
  const selectedParagraphIndex = useStateUpdate(0);
  const isParagraphActionsEnabled = useStateUpdate(false);
  const openConfirmModal = useStateUpdate(false);
  const isEditing = useStateUpdate(false);
  const openWaveEditModal = useStateUpdate(false);
  const tabValue = useStateUpdate(0);
  const isRecording = useStateUpdate(false);
  const showNoisyModal = useStateUpdate(false);
  const noisyModalMessage = useStateUpdate("");
  const noisyModalType = useStateUpdate(null);
  const regionStart = useStateUpdate(0);
  const regionEnd = useStateUpdate(1);
  const silenceCount = useStateUpdate(0);
  const waveModal = useStateUpdate(true);
  const selectedParagraph = useStateUpdate(null);
  const processingWave = useStateUpdate(false);
  const waveLoading = useStateUpdate(true);
  const clippingWave = useStateUpdate(false);
  const checkNewSilence = useStateUpdate(true);
  const silenceSeconds = useStateUpdate(0);
  const wavePlaying = useStateUpdate(false);
  const silencedWave = useStateUpdate(null);
  const editedWaves = useStateUpdate([]);
  const waveEdited = useStateUpdate(false);
  const disableClip = useStateUpdate(false);
  const audioLoaded = useStateUpdate(false);
  const waveBuffer = useStateUpdate(null);
  const context = useStateUpdate(null);
  const isPlaying = useStateUpdate(false);
  const isHideLeft = useStateUpdate(false);
  const keyPressed = useStateUpdate(null);

  const openPageViewModal = useStateUpdate(false);
  const openWarningModal = useStateUpdate(false);
  const confirmModalData = useStateUpdate({
    icon: null,
    title: null,
    message: null,
    confirmButton: null,
    cancelButton: null,
    onConfirm: null,
    onCancel: null,
  });
  const warningModalMessage = useStateUpdate("");
  const pages = useStateUpdate(null);
  const isRefreshing = useStateUpdate(true);
  const isFetching = useStateUpdate(true);
  const isFetchingPageContent = useStateUpdate(true);
  const pageUrl = useStateUpdate("");
  const bookDetails = useStateUpdate(null);
  const navigateTo = useStateUpdate(null);
  const textToRecord = useStateUpdate("");
  const textToRecordFontSize = useStateUpdate(TEXT_TO_RECORD_NORMAL_FONT_SIZE);
  const pageViewSize = useStateUpdate(PAGE_VIEW_NORMAL_SIZE);
  const isAddingParagraph = useStateUpdate(false);
  const isAudioUploading = useStateUpdate(false);
  const isDeleting = useStateUpdate(false);

  const threadTitle = useStateUpdate("");
  const description = useStateUpdate("");
  const message = useStateUpdate("");
  const threadPage = useStateUpdate(null);
  const threadParagraph = useStateUpdate(null);
  const recordedUserId = useStateUpdate(null);

  const isRecordingEnabled = useStateUpdate(false);

  const showPagesType = useStateUpdate(false);
  const openCreateThreadModal = useStateUpdate(false);

  const openWaveEditModalRef = useRef(openWaveEditModal.state);
  const isAddingParagraphRef = useRef(isAddingParagraph.state);
  const openPageViewModalRef = useRef(openPageViewModal.state);
  const openConfirmModalRef = useRef(openConfirmModal.state);
  const openWarningModalRef = useRef(openWarningModal.state);
  const showNoisyModalRef = useRef(showNoisyModal.state);
  const openCreateThreadModalRef = useRef(openCreateThreadModal.state);
  const openGuideLineModalRef = useRef(openGuideLineModal.state);
  const isEditingRef = useRef(isEditing.state);

  const setConfirmModalData = (
    icon,
    title,
    message,
    confirmButton,
    cancelButton,
    onConfirm,
    onCancel
  ) => {
    confirmModalData.update({
      icon,
      title,
      message,
      confirmButton,
      cancelButton,
      onConfirm,
      onCancel,
    });
    openConfirmModal.update(true);
  };

  const callUpdateBookStatus = async () => {
    const body = { statusCode: BookStatusCodes.audioMerging };
    const result = await updateBookStatus(bookId, body);
    result.status === 200 &&
      navigate(
        isAuthor ? "/dashboard" : `/projects/${bookDetails.state.projectId}`
      );
  };

  const callDeletePara = async (paraId) => {
    isDeleting.update(true);
    const result = await deleteParagraph(
      pages.state[selectedIndex.state].pageId,
      paraId
    );
    if (result.status === 200) {
      fetchPageDetails(pages.state[selectedIndex.state - 1].pageId);
      openConfirmModal.update(false);
      selectedParagraphIndex.update(1);
    }
  };

  const callCreatePara = async (body) => {
    const result = await createParagraph(
      pages.state[selectedIndex.state - 1].pageId,
      body
    );
    if (result.status === 200) {
      fetchPageDetails(pages.state[selectedIndex.state - 1].pageId);
      openConfirmModal.update(false);
      if (pages.state[selectedIndex.state - 1].isRecorded) {
        callUpdatePageStatus(
          pages.state[selectedIndex.state - 1].pageId,
          false
        );
      }
    }
  };

  const fetchBookDetails = async () => {
    const result = await getBookDetailsForRecording(bookId);
    if (result.status === 200) {
      const { data } = result.data;
      bookDetails.update(data);
    }
  };

  const callUpdatePageStatus = async (pageId, isRecorded) => {
    if (!pages.state[selectedIndex.state - 1].isRecorded) {
      const body = { isRecorded: isRecorded, pageNumber: selectedIndex.state };
      const result = await updatePageStatus(pageId, body);
      result.status === 200 && fetchBookDetails();
    }
  };

  const callUpdateParagraph = async (paraId, body) => {
    const result = await updateParagraph(
      pages.state[selectedIndex.state].pageId,
      paraId,
      body
    );
    if (result.status === 200) {
      fetchPageDetails(pages.state[selectedIndex.state - 1].pageId);
      openConfirmModal.update(false);
    }
  };

  const stopRecordingMp3 = (e) => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        let audio_length = Math.round((blob.size * 8) / (320 * 1000));
        if (audio_length <= 1) {
          noisyModalMessage.update("Audio is too short.");
          noisyModalType.update("warning");
          showNoisyModal.update(true);
        } else if (audio_length >= 180) {
          noisyModalMessage.update(
            `The audio is too long; the maximum length is 3 minutes.`
          );
          noisyModalType.update("warning");
          showNoisyModal.update(true);
        } else if (selectedIndex.state === 1) {
          getRecordingBuffer(buffer, blob);
        } else {
          fileCreationUpload(buffer, blob);
        }
      })
      .catch((e) => {
        alert("We could not retrieve your message");
      });
    isRecording.update(false);
  };

  const fileCreationUpload = (buffer, blob, audioLength) => {
    const fileName = `${pages.state[selectedIndex.state - 1].pageId}-${
      selectedParagraph.state.paragraphId
    }.${getAudioType()}`;
    const file = new File(buffer, fileName, {
      type: blob.type,
      lastModified: Date.now(),
    });

    prepareAudio(selectedParagraph);
    onUpload(file, 0, audioLength);
  };

  const getDecibelValue = (channelData, duration) => {
    let message = "";
    let acceptResult = false;
    let rms = getRmsValue(channelData);
    let decibel = 20 * Math.log10(rms);
    if (decibel < -60) {
      message = "Silent audio, noise free environment.";
      noisyModalType.update("success");
      acceptResult = true;
    } else if ((decibel > -60) & (decibel < -35)) {
      message = "Silent audio, acceptable environment.";
      noisyModalType.update("success");
      acceptResult = true;
    } else if ((decibel > -35) & (decibel < -25)) {
      message =
        "Very noisy environment. Please check your surroundings and try again.";
      noisyModalType.update("error");
    } else {
      message = "Silence recording not accepted due to a rowdy environment.";
      noisyModalType.update("error");
    }
    noisyModalMessage.update(message);
    return acceptResult;
  };

  const getRmsValue = (channelData) => {
    let sum = 0;
    for (let i in channelData) {
      let square = channelData[i] * channelData[i];
      sum = sum + square;
    }
    let rms = Math.sqrt(sum / channelData.length);
    return rms;
  };

  const getRecordingBuffer = async (buffer, blob, audioLength) => {
    return getAudioBufferFromBlob(blob, context.state)
      .then((response) => {
        let channelData = response.getChannelData(0);
        let acceptResult = getDecibelValue(channelData, response.duration);
        showNoisyModal.update(true);
        acceptResult && fileCreationUpload(buffer, blob, audioLength);
      })
      .catch((err) => console.log(err));
  };

  const startRecordingMp3 = (e) => {
    recorder
      .start()
      .then(() => {
        pauseAudio();
        isRecording.update(true);
      })
      .catch((e) => {
        console.error(`ERROR ${e}`);
      });
  };

  const previousPage = () => {
    if (
      !(
        (selectedIndex.state === 1 && selectedParagraphIndex.state === 1) ||
        isAudioUploading.state ||
        isRecording.state
      )
    ) {
      pauseAudio();
      if (selectedParagraphIndex.state === 1) {
        let prevIndex = selectedIndex.state - 1;
        while (
          prevIndex > 0 &&
          showPagesType.state &&
          pages.state[prevIndex - 1].isRecorded
        ) {
          prevIndex--;
        }
        if (prevIndex > 0) selectedIndex.update(prevIndex);
      } else {
        selectedParagraphIndex.update(selectedParagraphIndex.state - 1);
      }
    }
  };

  const nextPage = () => {
    if (
      !(
        (selectedIndex.state === pages.state.length &&
          selectedParagraphIndex.state === pageContents.state.length) ||
        isAudioUploading.state ||
        isRecording.state
      )
    ) {
      pauseAudio();
      if (selectedParagraphIndex.state === pageContents.state.length) {
        let nextIndex = selectedIndex.state + 1;
        while (
          nextIndex <= pages.state.length &&
          showPagesType.state &&
          pages.state[nextIndex - 1].isRecorded
        ) {
          nextIndex++;
        }
        if (nextIndex <= pages.state.length) selectedIndex.update(nextIndex);
      } else {
        selectedParagraphIndex.update(selectedParagraphIndex.state + 1);
      }
    }
  };

  const pauseAudio = () => {
    sound?.pause();
    isPlaying.update(false);
  };

  const playAudio = (e) => {
    sound?.play();
    sound?.addEventListener("ended", () => stopPlaying());
    sound && isPlaying.update(true);
  };

  function startRecordingWebm() {
    pauseAudio();
    isRecording.update(true);
    return navigator.mediaDevices
      .getUserMedia({
        audio: {
          echoCancellation: true,
        },
      })
      .then((stream) => {
        audioBlobs = [];
        capturedStream = stream;

        // Use the extended MediaRecorder library
        mediaRecorder = new MediaRecorder(stream, {
          mimeType: `audio/${getAudioType()}`,
        });

        // Add audio blobs while recording
        mediaRecorder.addEventListener("dataavailable", (event) => {
          audioBlobs.push(event.data);
        });

        mediaRecorder.start();
      })
      .catch((e) => {
        console.error(e);
      });
  }

  function stopRecordingWebm() {
    return new Promise((resolve) => {
      if (!mediaRecorder) {
        resolve(null);
        return;
      }

      mediaRecorder.addEventListener("stop", () => {
        const mimeType = mediaRecorder.mimeType;
        const audioBlob = new Blob(audioBlobs, { type: mimeType });

        if (capturedStream) {
          capturedStream.getTracks().forEach((track) => track.stop());
        }

        resolve(audioBlob);
      });

      mediaRecorder.stop();
    });
  }

  const handleStopRecordingWebm = async (seconds) => {
    let blob = await stopRecordingWebm();

    // let audio_length = Math.round((blob.size * 8) / (320 * 1000));
    let audioLength = seconds - 1;
    if (audioLength <= 1) {
      noisyModalMessage.update("Audio is too short.");
      noisyModalType.update("warning");
      showNoisyModal.update(true);
    } else if (audioLength >= 180) {
      noisyModalMessage.update(
        `The audio is too long; the maximum length is 3 minutes.`
      );
      noisyModalType.update("warning");
      showNoisyModal.update(true);
    } else if (selectedIndex.state === 1) {
      getRecordingBuffer([blob], blob, audioLength);
    } else {
      fileCreationUpload([blob], blob, audioLength);
    }
    isRecording.update(false);
  };

  const handleChange = (event, newValue) => {
    tabValue.update(newValue);
  };

  const fetchPageDetails = async (pageId) => {
    isRefreshing.update(true);
    const result = await pageDetails(pageId);
    if (result.status === 200) {
      const { data } = result.data;
      pageContents.update(data);
      isRefreshing.update(false);
      isFetching.update(false);
      isFetchingPageContent.update(false);
    }
  };

  const onUpload = async (file, isOptimized, audioLength) => {
    const parser = new UAParser();
    const browser = parser.getBrowser();
    const os = parser.getOS();

    const pageId = pages.state[selectedIndex.state - 1].pageId;
    const paragraphId = selectedParagraph.state.paragraphId;
    isAudioUploading.update(true);

    const audioDuration = audioLength
      ? audioLength
      : await getAudioLength(file)
          .then((duration) => {
            return duration;
          })
          .catch((error) => {
            console.error("Error getting audio duration:", error);
          });

    let formData = new FormData();
    formData.append("paragraphAudio", file);
    formData.append("paragraphNumber", selectedParagraphIndex.state);
    formData.append("pageNumber", selectedIndex.state);
    formData.append("audioDuration", audioDuration);
    formData.append("browserName", browser.name || "Unknown");
    formData.append(
      "browserVersion",
      versionFormatToTwoSegments(browser.version) || "Unknown"
    );
    formData.append("browserOs", os.name || "Unknown");
    formData.append(
      "browserOsVersion",
      versionFormatToTwoSegments(os.version) || "Unknown"
    );
    let result = await uploadAudio(pageId, paragraphId, formData, isOptimized);
    if (result.status === 200) {
      fetchPageDetails(pageId);
      checkIsRecorded();
      isAudioUploading.update(false);
    }
  };

  const checkIsRecorded = (buffer, blob) => {
    let isRecorded = true;
    pageContents.state.forEach((page) => {
      if (
        !page.isRecorded &&
        !(selectedParagraph.state.paragraphId === page.paragraphId)
      ) {
        isRecorded = false;
      }
    });
    if (isRecorded) {
      callUpdatePageStatus(
        pages.state[selectedIndex.state - 1].pageId,
        isRecorded
      );
    }
  };

  const prepareAudio = (data) => {
    if (data.isRecorded) {
      let url = getAudioUrl(data.audioUrl) + "?t=" + new Date().getTime();
      sound = new Audio(url);
      sound.load();
      audioLoaded.update(true);
    } else {
      sound = null;
      audioLoaded.update(false);
      waveBuffer.update(null);
    }
  };

  const stopPlaying = () => {
    isPlaying.update(false);
  };

  const checkIsRecordedUserIsAdmin = async () => {
    const result = await getUserDetail(recordedUserId.state);
    if (result.status === 200) {
      const { data } = result.data;
      if (data.roleId === adminRoleId) {
        recordedUserId.update(null);
      }
    }
  };

  return (
    <RecordingPageContext.Provider
      value={{
        selectedIndex,
        totalPages,
        openGuideLineModal,
        isRecordingCompleted,
        callUpdateBookStatus,
        setConfirmModalData,
        openUnRecordedPagesModal,
        pagesNotRecorded,
        pageContents,
        selectedParagraphIndex,
        callDeletePara,
        isParagraphActionsEnabled,
        callCreatePara,
        callUpdateParagraph,
        openConfirmModal,
        isEditing,
        isRecording,
        stopRecordingMp3,
        startRecordingMp3,
        previousPage,
        nextPage,
        pages,
        isAudioUploading,
        keyPressed,
        isPlaying,
        pauseAudio,
        playAudio,
        isRecordingEnabled,
        startRecordingWebm,
        handleStopRecordingWebm,
        openPageViewModal,
        pageUrl,
        showPagesType,
        regionStart,
        regionEnd,
        silenceCount,
        waveLoading,
        processingWave,
        clippingWave,
        wavePlaying,
        checkNewSilence,
        silenceSeconds,
        silencedWave,
        editedWaves,
        waveEdited,
        disableClip,
        selectedParagraph,
        context,
        waveModal,
        prepareAudio,
        onUpload,
        bookDetails,
        tabValue,
        handleChange,
        openWaveEditModal,
        textToRecord,
        textToRecordFontSize,
        pageViewSize,
        fetchPageDetails,
        isRefreshing,
        openCreateThreadModal,
        threadTitle,
        description,
        threadPage,
        threadParagraph,
        openWaveEditModalRef,
        isAddingParagraphRef,
        openPageViewModalRef,
        openConfirmModalRef,
        isAddingParagraph,
        openWarningModalRef,
        showNoisyModalRef,
        openCreateThreadModalRef,
        openGuideLineModalRef,
        isEditingRef,
        showNoisyModal,
        openWarningModal,
        warningModalMessage,
        navigateTo,
        recordedUserId,
        isFetchingPageContent,
        isDeleting,
        bookId,
        noisyModalType,
        noisyModalMessage,
        isHideLeft,
        confirmModalData,
        message,
        isFetching,
        checkIsRecorded,
        fetchBookDetails,
        checkIsRecordedUserIsAdmin,
      }}
    >
      {children}
    </RecordingPageContext.Provider>
  );
};

export default RecordingPageContextProvider;
